import 'babel-polyfill';
import 'react-app-polyfill/ie11';

import "react-responsive-carousel/lib/styles/carousel.min.css";

import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  Link
} from 'react-router-dom';
import './index.css';
import './menu.css';
import './statement.css';
import './cv.css';
import { Carousel, CarouselProps } from 'react-responsive-carousel';
import data from './data';
import {WorkData} from './data';
import {RouteComponentProps} from 'react-router';

function thumbnailImagePath(image: string) {
  return "/image/thumbnail/" + image;
}

function scaledImagePath(image: string) {
  return "/image/scaled/" + image;
}

function nameToId(name: string) {
  return name.toLowerCase().replace(/ /g, "_");
}

class Menu extends Route {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      shouldHide: true,
    };
  }

  subItemClasses(path: string) {
    let location = this.props.location!;
    if ("/" + path === location.pathname) {
      return "subItem subItemActive";
    } else {
      return this.state.shouldHide
        && (location.pathname !== "/collage"
          && location.pathname !== "/drawing"
          && location.pathname !== "/sculpture") ? "subItem hide" : "subItem";
    }
  }

  topItemClasses(path: string) {
    let location = this.props.location!;
    if (location.pathname.startsWith("/" + path) ||
        ("/" === location.pathname && path === "work")) {
      return "topItem topItemActive";
    } else {
      return "topItem"
    }
  }

  render() {
    return (
      <div className="menu">
        <div className="name"><Link to="/">MAHAL DE MAN</Link></div>
        <div className="menuItems">
          <div className="item"><Link to="/work" className={this.topItemClasses("work")}>WORK</Link></div>
          <div className="item"><Link to="/cv" className={this.topItemClasses("cv")}>CV</Link></div>
          <div className="item"><Link to="/statement" className={this.topItemClasses("statement")}>STATEMENT</Link></div>
          <div className="item"><Link to="/contact" className={this.topItemClasses("contact")}>CONTACT</Link></div>
        </div>
      </div>
    );
  }

  startHover() {
    this.setState({ shouldHide: false });
  }

  endHover() {
    this.setState({ shouldHide: true });
  }
}

function Contact() {
  return (
    <div className="contact">
      <div dangerouslySetInnerHTML={{ __html: data.contact }} />
    </div>
  );
}

function Statement() {
  return (
    <div className="statement" dangerouslySetInnerHTML={{ __html: data.statement }} />
  );
}

function CV() {
  let out: JSX.Element[] = []
  for (var type in data.cv) {
    let rows: JSX.Element[] = []
    for (var i = 0; i < data.cv[type].items.length; i++) {
      var cv = data.cv[type].items[i];
      rows.push((
        <tr key={i}>
          <td className="date">{cv.date}</td>
          <td className="cvcontent">{cv.what}</td>
        </tr>
      ));
    };

    out.push((<h1 key={type} >{data.cv[type].header}</h1>));
    out.push((
      <table key={type + "table"}>
        <tbody>
          {rows}
        </tbody>
      </table>
    ));
  }
  return (
    <div className="cv">
      <div dangerouslySetInnerHTML={{ __html: data.cvHeader }} />
      {out}
    </div>
  );
}

function NotFound() {
  return (
    <div className="contact">
      Page not found
    </div>
  );
}

interface MyCarouselProps extends CarouselProps {
  selectedId: string;
}

type WorkParams =  { workid: string };

class Work extends Route<RouteComponentProps<WorkParams>> {
  handleClick(name: string) {
    this.props.history.push('/work/' + name)
  }

  render() {
    let thework: WorkData|null = null
    let prev: WorkData|null = null
    let next: WorkData|null = null
    for (var i in data.works) {
      // Why javascript, why.
      let num = parseInt(i, 10)
      if (nameToId(data.works[num].name) === this.props.match.params.workid) {
        prev = data.works[num-1]
        thework = data.works[num]
        next = data.works[num+1]
        break;
      }
    }
    if (thework === null) {
      return (<Redirect to="/work" />)
    }

    var previousButton = (prev != null) ?
      (<img alt="left" src="/left.png" className="directionButton" onClick={() => this.handleClick(nameToId(prev!.name))} />)
      : (<div></div>);
    var nextButton = (next != null) ?
      (<img alt="right" className="right directionButton" src="/left.png" onClick={() => this.handleClick(nameToId(next!.name))} />)
      : (<div></div>);

    return (
      <div  style={{margin: "auto"}}>
        {previousButton} {nextButton}
        <DemoCarousel selectedId={this.props.match.params.workid} />
      </div>
    );
  }

}

class DemoCarousel extends React.Component<MyCarouselProps> {
  render() {

      var items: JSX.Element[] = [];
      var selected = 0;
      for (var i in data.works) {
        // Why javascript, why.
        let num = parseInt(i, 10);
        if (nameToId(data.works[num].name) === this.props.selectedId) {
          selected = num;
        }
        items.push((
          <div className="full" key={data.works[num].name}>
                  <img alt={data.works[num].name} src={scaledImagePath(data.works[num].image)} />
                  <p className="description">{data.works[num].description}</p>
          </div>
        ));
      }

      return (
        <div className="pruts">
          <Carousel showArrows={false} swipeScrollTolerance={60} selectedItem={selected} useKeyboardArrows={true} /*dynamicHeight={true}*/ showStatus={false} showThumbs={false} showIndicators={false}>
              {items}
          </Carousel>
        </div>
      );
  }
};

class Works extends Route<RouteComponentProps> {
  handleClick(name: string) {
    this.props.history.push('/work/' + name)
  }

  render() {
    var works: WorkData[]|null = null;
    if (this.props.match.path === "/collage") {
      works = data.works.filter(function (work: WorkData) {
        return work.category === "collage";
      });
    } else if (this.props.match.path === "/drawing") {
      works = data.works.filter(function (work: WorkData) {
        return work.category === "drawing";
      });
    } else if (this.props.match.path === "/sculpture") {
      works = data.works.filter(function (work: WorkData) {
        return work.category === "sculpture";
      });
    } else {
      works = data.works;
    }
    return (
      <div className="images">
        {works.map((work: WorkData) => (
          <img key={work.name}
            className="image"
            onClick={() => this.handleClick(nameToId(work.name))}
            src={thumbnailImagePath(work.image)}
            alt={work.name} />
        ))}
      </div>
    );
  }
}

class SignupForm extends React.Component {
  render() {
    const signup_form = `<!-- Begin Mailchimp Signup Form -->
    <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
    <style type="text/css">
      #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
        We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    </style>
    <div id="mc_embed_signup">
    <form action="https://mahaldeman.us20.list-manage.com/subscribe/post?u=392ed7291e0b1592e2e8d28dd&amp;id=b0adc42fed" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll">

    <div class="mc-field-group">
      <label for="mce-EMAIL">Email Address </label>
      <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
    </div>
    <div class="mc-field-group">
      <label for="mce-FNAME">First Name </label>
      <input type="text" value="" name="FNAME" class="" id="mce-FNAME">
    </div>
    <div class="mc-field-group">
      <label for="mce-LNAME">Last Name </label>
      <input type="text" value="" name="LNAME" class="" id="mce-LNAME">
    </div>
      <div id="mce-responses" class="clear">
        <div class="response" id="mce-error-response" style="display:none"></div>
        <div class="response" id="mce-success-response" style="display:none"></div>
      </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_392ed7291e0b1592e2e8d28dd_b0adc42fed" tabindex="-1" value=""></div>
        <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
        </div>
    </form>
    </div>
    <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
    <!--End mc_embed_signup--></div>`;
    return (
      <div dangerouslySetInnerHTML={{ __html: signup_form }} />
    );
  }
}

class Site extends React.Component {
  render() {
    return (
      <Router>
        <div className="container">
          <Route path="/" component={Menu} />
          <Switch>
            <Route exact path="/" component={Works} />
            <Route exact path="/beta" component={Works} />
            <Route exact path="/work" component={Works} />
            <Route path="/work/:workid" component={Work} />>
            <Route path="/contact" component={Contact} />
            <Route path="/statement" component={Statement} />
            <Route path="/cv" component={CV} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <img src="/footer.png" alt="Footer" className="footer"></img>
      </Router>
    );
  }
}

// ========================================

ReactDOM.render(
  <Site />,
  document.getElementById('root')
);
