export interface WorkData {
  name: string, category: string, image: string, description: string,
}

export interface CVItem {
  date: string, what: string,
}

export interface CVHeader {
  header: string, items: CVItem[],
}

export interface Data {
  contact: string, statement: string, carousel: string[], works: WorkData[],
      cvHeader: string, cv: CVHeader[],
}

const data: Data = {
  'contact':
      '<p>Please feel free to contact me to discuss my work further via <a class=\'email\' href=\'mailto:mail@mahaldeman.nl\'>mail@mahaldeman.nl</a> or follow me on <a style=\'text-decoration: underline\' href=\'https://www.instagram.com/mahaldeman\' target=\'_blank\'>Instagram</a></p>',
  'statement': `
    <p> <b>
    About me, about you </b> </p>
    <p> I am interested in the relation and contrast between the deeply personal and the universal.
    My experiences as an individual are not unique, and certain questions and fears are shared
    by all of us. My work is about capturing a sense of connection and continuity. I use drawing
    and collage to layer, label and archive images and thoughts. I am interested in objects and
    stories that give us a sense of place or purpose, like tools (stone age and modern) and art
    objects; and objects that appear time-less, like mountains, rocks and celestial bodies.</p>
    <p>I like using found images because they allow me to make visual associations and they create
    a space for chance and intuition. I also use text, both typeset and handwriting, depending on
    the subject. I consider text and drawings to be closely related, they both depend on the ‘line’
    and hand,they are remnants of a gesture and they both are expressions of abstract thoughts.
    </p>`,
  'carousel': [
    'TheStoryOfASpecies_Detail_FULL.jpeg',
    'TheStoryOfASpecies_Detail_FULL.jpeg',
    'TheStoryOfASpecies_Detail_FULL.jpeg',
  ],
  'works': [
    {
      'name': 'Hand Axe Woburn Place',
      'category': 'drawing',
      'image': 'HandAxeWoburnPlace.jpg',
      'description': 'Hand Axe Woburn Place, pencil and acrylic paint on paper, 2023. Found in 1902 in Camden while repairing a drain, currently in the British Museum collection.',
    },
    {
      'name': 'I WIll Cherish This',
      'category': 'drawing',
      'image': 'IWillCherishThis.jpg',
      'description': 'I Will Cherish This, eco-banner print, original: ink erased from found image, 2023',
    },
    /*
    {
      'name': 'Rock-drawing Exchange: Sara',
      'category': 'drawing',
      'image': 'RDE_Sara.jpg',
      'description': 'Rock-Drawing Exchange: Sara, pencil on paper, 2022',
    },*/
    {
      'name': 'Lost and Found (Flint Flake from Hackney)',
      'category': 'drawing',
      'image': 'LostandFoundHackney.jpg',
      'description': 'Lost and Found (Flint Flake from Hackney), pencil on paper, 2023 - Flint flake found in Hackney. Currently in the British museum collection',
    },
    /*
    {
      'name': 'Rock-drawing Exchange: Jools',
      'category': 'drawing',
      'image': 'RDE_Jools.jpg',
      'description': 'Rock-Drawing Exchange: Jools, pencil on paper, 2022',
    },*/
    {
      'name': 'Rock-drawing Exchange: Clare S',
      'category': 'drawing',
      'image': 'RDE_Clare.jpg',
      'description': 'Rock-Drawing Exchange: Clare S, pencil on paper, 2022',
    },
    {
      'name': 'Dunbridge Pit I',
      'category': 'drawing',
      'image': 'HandaxeBM.jpeg',
      'description': 'Dunbridge Pit I, pencil on paper, 2022 - Flint handaxe from the Lower-Middle Palaeolithic, found in Dunbridge, Hampshire. Currently in the British Museum collection.',
    },
    {
      'name': 'WIP at Ipswich Museum',
      'category': 'drawing',
      'image': 'Ipswichmuseum.jpg',
      'description': 'WIP at Ipswich Museum (for About Me, About You), pencil on paper, 2022',
    },
    {
      'name': 'The Art Station Residency Showcase',
      'category': 'drawing',
      'image': 'Artstation.jpg',
      'description': 'The Art Station Residency Showcase, Saxmundham, 2022. Photo by Malcolm Watson.',
    },
    {
      'name': 'Charcoal mural',
      'category': 'drawing',
      'image': 'Artstation2.jpg',
      'description': 'About Me, About You, charcoal mural drawing, 2022. Photo by Malcolm Watson.',
    },

      {
      'name': 'Rock-drawing Exchange: JC, drawing',
      'category': 'drawing',
      'image': 'jc_drawing.jpg',
      'description': 'Rock-Drawing Exchange: JC, pencil and ink on paper, 2022',
    },
    /*{
      'name': 'Rock-drawing Exchange: JC, rock',
      'category': 'drawing',
      'image': 'jc_rock.jpg',
      'description': 'Rock-Drawing Exchange: Obsidian, Kenya',
    },*/
    /*{
      'name': 'Rock-drawing Exchange: JC2, rock',
      'category': 'drawing',
      'image': 'jc2_rock.jpg',
      'description': 'Rock-Drawing Exchange: Porcupine quill, Kenya',
    },*/
    {
      'name': 'Rock-drawing Exchange: JC2, drawing',
      'category': 'drawing',
      'image': 'jc2_drawing.jpg',
      'description': 'Rock-Drawing Exchange: JC2, pencil on paper, 2022',
    },
    {
      'name': 'Rock-drawing Exchange: Cookie, rock',
      'category': 'drawing',
      'image': 'cookie_rock.jpg',
      'description': 'Rock-Drawing Exchange: Rock',
    },
    {
      'name': 'Rock-drawing Exchange: Cookie, drawing',
      'category': 'drawing',
      'image': 'cookie_drawing.jpg',
      'description': 'Rock-Drawing Exchange: Cookie, silverpoint on paper, 2022',
    },
    {
      'name': 'I Will Wait',
      'category': 'drawing',
      'image': 'iwillwait.jpg',
      'description': 'I Will Wait, ink erased from inkjet print, 2021',
    },
    {
      'name': 'I Will Wait - Sirens',
      'category': 'drawing',
      'image': 'iwillwaitinstall.jpg',
      'description': 'I Will Wait, Sirens, Deptford X, 2021. Photo by Sisetta Zappone',
    },    
    {      
      'name': 'I Miss Your Touch',
      'category': 'drawing',
      'image': 'imissyourtouch.jpeg',
      'description': 'I Miss Your Touch, billboard print, original: ink erased from found image, 2021',
    },
    {
      'name': 'I Miss Your Touch - Billboard',
      'category': 'drawing',
      'image': 'billboard.jpg',
      'description': 'Tannery Projects Billboard Commission, Tannery Arts, London, 2021. Photo by Ben Deakin',
    },
    {
      'name': 'It Started with Touch',
      'category': 'drawing',
      'image': 'itendswithtime.jpg',
      'description': 'It Started with Touch, spread from bound collection of drawings and collages, 2021',
    },
    {
      'name': 'It Started with Touch 2',
      'category': 'drawing',
      'image': 'radio.jpeg',
      'description': 'It Started with Touch, spread from bound collection of drawings and collages, 2021',
    },
    {
      'name': 'Moonrocks',
      'category': 'drawing',
      'image': 'moonrocks.jpg',
      'description': 'Touchstone, ceramics, 2021',
    },
    /*{
      'name': 'Mini Pinch Pots in Sea Green',
      'category': 'sculpture',
      'image': 'pinchpots.jpg',
      'description': 'Mini Pinch Pots in Sea Green, glazed ceramics, 2021',
    },*/
    {
      "name": "Sketchbook",
      "category": "drawing",
      "image": "sketchbook1.jpg",
      "description": "Pages from Sketchbook, 2020",
    },
    {
      'name': 'Anvil',
      'category': 'drawing',
      'image': 'anvil.jpeg',
      'description': 'Anvil, pencil drawing, 2020',
    },
    {
      "name": "Drawing Biennial 2021",
      "category": "drawing",
      "image": "drawingbiennial21.jpg",
      "description": "Drawing Biennial 2021, Drawing Room, London, 2021",
    },

    {
      'name': 'Hammerstone',
      'category': 'sculpture',
      'image': 'hammerstone.jpg',
      'description': 'Hammerstone, pencil drawing, 2019 (available as print)',
    },
    {
      'name': 'Flint Spearhead',
      'category': 'drawing',
      'image': 'flint_spearhead.jpg',
      'description':
          'Flint Spearhead, pencil drawing, 2019 (available as print)',
    }, 
    /*
    {
      'name': 'Barbed and Tanged Arrowhead',
      'category': 'drawing',
      'image': 'barbed_tanged_arrowhead.jpg',
      'description':
          'Barbed and Tanged Arrowhead, pencil drawing, 2019 (available as print)',
    },
    {
      'name': 'Leaf Shaped Arrowhead',
      'category': 'drawing',
      'image': 'leaf_shaped_arrowhead.jpg',
      'description':
          'Leaf Shaped Arrowhead, pencil drawing, 2019 (available as print)',
    },
    */
    {
      "name": "Sirens 2019 - 1",
      "category": "drawing",
      "image": "sirens19a.jpg",
      "description": "Sirens, Arthouse 1, London, 2019",
    },
    {
      "name": "Sirens 2019 - 2",
      "category": "drawing",
      "image": "sirens19.jpg",
      "description": "Sirens, Arthouse 1, London, 2019",
    },
    {
      'name': 'ISS',
      'category': 'drawing',
      'image': 'iss.jpg',
      'description': 'ISS, pencil wall drawing, 2019',
    },
    {
      'name': 'Lander',
      'category': 'drawing',
      'image': 'lander.jpg',
      'description': 'Lunar Lander, pencil wall drawing, 2018',
    },
    /*{
      'name': 'Astronaut',
      'category': 'drawing',
      'image': 'astronaut.jpg',
      'description': 'Astronaut, pencil wall drawing, 2018',
    },*/
    {
      'name': 'The Journey Starts',
      'category': 'drawing',
      'image': 'the_journey_starts_here.jpg',
      'description': 'The Journey Starts, ink drawing, 2018',
    },
    {
      "name": "The Journey Starts - Drawing Biennial",
      "category": "drawing",
      "image": "Drawing_biennial19.jpg",
      "description": "Drawing Biennial 2019, Drawing Room, London, 2019",
    },
    {
      'name': 'Icosahedron',
      'category': 'sculpture',
      'image': 'icosahedron.jpg',
      'description': 'Icosahedron, modular origami with found images, 2018',
    },
    /*
    {
      'name': 'Dodecahedron',
      'category': 'sculpture',
      'image': 'dodecahedron.jpg',
      'description': 'Dodecahedron, modular origami with found images, 2018',
    },
    
    {
      'name': 'Octahedron',
      'category': 'sculpture',
      'image': 'octahedron.jpg',
      'description': 'Octahedron, modular origami with found images, 2018',
    },
    {
      'name': 'Hexahedron',
      'category': 'sculpture',
      'image': 'hexahedron.jpg',
      'description': 'Hexahedron, modular origami with found images, 2018',
    },
    {
      'name': 'Tetrahedron',
      'category': 'sculpture',
      'image': 'tetrahedron.jpg',
      'description': 'Tetrahedron, modular origami with found images, 2018',
    },*/
    {
      'name': 'Wish You Were Here',
      'category': 'drawing',
      'image': 'wish_you_were_here.jpg',
      'description': 'Wish You Were Here, ink drawing on found image, 2018',
    },
    {
      'name': 'Twist',
      'category': 'collage',
      'image': 'Twist_FULL.jpeg',
      'description': 'Twist, digital collage with found image, 2015',
    },
    {
      'name': 'Kew',
      'category': 'collage',
      'image': 'Kew_FULL.jpg',
      'description': 'Kew, collage with found images, 2015',
    },
    {
      'name': 'Ad Infinitum',
      'category': 'drawing',
      'image': 'AdInfinitum_Detail_FULL.jpeg',
      'description': 'Ad Infinitum, chalk drawing, 2015',
    },
    {
      'name': 'Ad Infinitum - Lumen',
      'category': 'drawing',
      'image': 'AdInfinitum_Board_FULL.jpg',
      'description': 'Lumen Residency Exhibition, Chiesa San Francesco, Atina, IT, 2015',
    },
    {
      'name': 'The Story of a Species',
      'category': 'drawing',
      'image': 'TheStoryOfASpecies_Detail_FULL.jpeg',
      'description':
          'The Story of a Species, drawings and collages with found images, 2015',
    },
    {
      'name': 'The Story Of A Species - Lumen',
      'category': 'drawing',
      'image': 'TheStoryOfASpecies_Board_FULL.jpeg',
      'description':
          'Lumen Residency Exhibition, Chiesa San Francesco, Atina, IT, 2015',
    },
    {
      'name': 'To Go Where No Man Has Gone Before',
      'category': 'drawing',
      'image': 'ToGoWhereNoManHasGoneBefore_FULL.jpeg',
      'description':
          'To Go Where No Man Has Gone Before, ink and pencil drawing, 2013',
    },
    {
      'name': 'Vide Super',
      'category': 'sculpture',
      'image': 'InstallIII.jpg',
      'description': 'Vide Super #1: On the Contrary, Nieuwe Vide, Haarlem, NL, 2013',
    },
    {
      'name': 'Rise',
      'category': 'collage',
      'image': 'Rise_FULL.jpg',
      'description':
          'Rise, digital collage with found images, 2012 (available as print)',
    },
    {
      'name': 'Rise - Vide Super',
      'category': 'collage',
      'image': 'Rise_Slide_FULL.jpg',
      'description':
          'Rise, shown as slide projection at Nieuwe Vide, Haarlem NL, 2013',
    },

    /*
    {
      "name": "Untitled (Three Spheres)",
      "category": "sculpture",
      "image": "Untitled(ThreeSpheres).jpg",
      "description": "Untitled (Three Spheres), glazed and unglazed fired clay,
    2012-2013",
    },
    {
      "name": "Untitled (SpaceCube)",
      "category": "sculpture",
      "image": "Untitled(SpaceCube)_FULL.jpg",
      "description": "Untitled (SpaceCube), found images and tape, 2011",
    },
    {
      "name": "Untitled (Linear I)",
      "category": "drawing",
      "image": "Untitled(LinearI)_FULL.jpg",
      "description": "Untitled (Linear I), drawing on found image, 2014",
    },
    {
      "name": "Untitled (Linear II)",
      "category": "drawing",
      "image": "Untitled(LinearII)_FULL.jpg",
      "description": "Untitled (Linear II), drawing on found image, 2014",
    },
    {
      "name": "Untitled (Linear III)",
      "category": "drawing",
      "image": "Untitled(LinearIII)_FULL.jpg",
      "description": "Untitled (Linear III), drawing on found image, 2014",
    },
    {
      "name": "Untitled (Linear IV)",
      "category": "drawing",
      "image": "Untitled(LinearIV)_FULL.jpg",
      "description": "Untitled (Linear IV), drawing on found image, 2014",
    },
    {
      "name": "Untitled (Pots)",
      "category": "sculpture",
      "image": "Untitled(Pots).jpg",
      "description": "Untitled (Pots), clay sculptures and pencil drawing,
    2012",
    },
    {
      "name": "Install II",
      "category": "sculpture",
      "image": "InstallII.jpg",
      "description": "Installation view at Royal Academy of Art, The Hague, NL",
    },
    {
      "name": "Untitled (Postcard)",
      "category": "sculpture",
      "image": "Untitled(Postcard)_FULL.jpg",
      "description": "Untitled (Postcard), clay sculpture and postcard, 2011",
    },
    {
      "name": "Install I",
      "category": "sculpture",
      "image": "InstallI.jpg",
      "description": "Installation view at W139, Amsterdam, NL",
    },*/
  ],
  'cvHeader': `
    <p>*1990, The Netherlands</p>
    <p>Lives and works in London</p>
  `,
  'cv': [
    {
      header: 'EDUCATION',
      items: [
        {
          date: '2008-2012',
          what: 'BA Fine Art, Royal Academy of Art, The Hague, NL',
        },
        {
          date: '2010',
          what: 'Erasmus Exchange, Weißensee Academy of Art, Berlin, DE',
        }
      ],
    },
    {
      header: 'EXHIBITIONS',
      items: [
        {
          date: '2023',
          what: 'SOLO: Lost and Found, Glass Cloud at Camden People\'s Theatre, UK',
        },
        {
          date: '2023',
          what: 'Side Step, Set Ealing, London, UK',
        },
        {
          date: '2022',
          what: 'DUO: Tracing Time, 73 Tontine Street (CT20), Folkestone, UK',
        },
        {
          date: '2022',
          what: 'studioUS, PRIMEYARC, Great Yarmouth, UK',
        },
        {
          date: '2022',
          what: 'SOLO: About Me About You, Residency Showcase at The Art Station, Saxmundham, UK',
        },
        {
          date: '2022',
          what: 'Sirens, hARTslane, London, UK',
        },
        {
          date: '2021',
          what: 'Sirens, Deptford X, London, UK',
        },
        {
          date: '2021',
          what: 'SOLO: Tannery Projects Billboard Commission, Tannery Arts, London, UK',
        },
        {
          date: '2021',
          what: 'Drawing Biennial 2021, Drawing Room, London, UK',
        },
        {
          date: '2020',
          what: 'Lockdown Residency Exhibition, Kunstmatrix.com, Online',
        },
        {
          date: '2020',
          what: 'Drawing & Studio, London Square Bermondsey, London, UK',
        },
        {
          date: '2019',
          what: 'Sirens, Safehouse 1, London, UK',
        },
        {
          date: '2019',
          what: 'Drawing Biennial 2019, Drawing Room, London, UK',
        },
        {
          date: '2018',
          what: 'Glass Cloud Winter Fundraiser, Tin Cafe, London, UK',
        },
        {
          date: '2017',
          what: 'Tannery Arts Group Show 2017, Tannery Arts, London, UK',
        },
        {
          date: '2016',
          what: 'Local London, Vinyl, London, UK',
        },
        {
          date: '2016',
          what: 'Of Stones and Stars, Print House Gallery, London, UK',
        },
        {
          date: '2015',
          what: 'La Luce delle Stelle, Crypt Gallery, London, UK',
        },
        {
          date: '2015',
          what: 'Lumen Residency Exhibition, Chiesa San Francesco, Atina, IT',
        },
        {
          date: '2015',
          what: 'Tending to Infinity, Carousel, London, UK',
        },
      ],
    },
    {
      header: 'RESIDENCIES',
      items: [
        {
          date: '2022',
          what: 'The Art Station, Saxmundham, UK',
        },
        {
          date: '2020',
          what: 'Lockdown Residency, London, UK',
        },
        {
          date: '2015',
          what: 'Lumen Residency, Atina, IT',
        },
      ],
    },
    {
      header: 'COMMISSION',
      items: [
        {
          date: '2021',
          what: 'Tannery Projects Billboard Commission, Tannery Arts, London, UK',
        },
      ],
    },
    /*{
      header: 'PUBLICATIONS',
      items: [
        {
          date: '2015, November 5',
          what: 'Buoso, Sara. “Tending to Infinity,” Juliet Art Magazine',
        },
        {
          date: '2014, November 3',
          what: 'Buoso, Sara. “Dialogo con Mahal de Man,” SPAM #11',
        },
        {
          date: '2014, May 28',
          what:
              'Buoso, Sara. “In dialogue with Mahal de Man,” Juliet Art Magazine',
        },
        {
          date: '2013, November 13',
          what: '“De Grote Haagse Kunstkalender 2014,” Trichis',
        },
        {
          date: '2013, August 24',
          what:
              'Klaver, Wilma. “Drietal gaat grenzen voorbij,” Haarlems Dagblad',
        },
        {
          date: '2013, June 13',
          what: 'Klop, Mathanja. “Als ik me had laten afwijzen..,” Kunstpit',
        },
        {
          date: '2012, December 1',
          what: 'Ruyters, Domeniek. “Leven als zoekmachine,” Metropolis M',
        },
        {
          date: '2012, April 7',
          what:
              'Schellinkhout Diaz, Nienke. “De essentie van Mahal de Man,” Mister Motley',
        },
      ],
    },*/
  ]
}

export default data;
